import { useEffect, useRef, useState } from 'react';

function HomeReel() {
   const [index, setIndex] = useState(0);
   const contRef = useRef();
   const divRef = useRef();
   const initialScale = 0.5;

   useEffect(() => {
      var distanceFromTop = contRef.current.getBoundingClientRect().top + window.scrollY;
      var scaleIncrementPerPixelScrolled = (1 - initialScale) / distanceFromTop;

      function scrollHandler() {
         var scale;
         if (window.scrollY < distanceFromTop) {
            scale = initialScale + scaleIncrementPerPixelScrolled * window.scrollY;
         }
         else {
            scale = 1;
         }
         divRef.current.style.transform = `scale(${scale})`;
      }
      window.addEventListener('scroll', scrollHandler);
      return () => {
         window.removeEventListener('scroll', scrollHandler);
      }
   }, []);

   useEffect(() => {
      var id = setInterval(() => {
         setIndex(index => (index + 1) % 9);
      }, 1500);

      return () => {
         clearInterval(id);
      }
   }, []);

   return (
      <div ref={contRef} className="hero-reel-cont">
         <div ref={divRef} className="hero_reel" style={{transform: `scale(${initialScale})`}}>
            {new Array(9).fill(0).map((_, i) => (
               <img style={{
                  display: index === i ? 'block' : 'none'
               }} src={`/assets/reel/${i + 1}.avif`} />
            ))}
         </div>
      </div>
   );
}

export default HomeReel;