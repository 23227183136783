import LazyImage from '../../components/LazyImage';
import ProjectView from '../Project';
import images from '../../data/coronicles.json';


function CoroniclesProjectView() {
   return (
      <ProjectView title="Coronicles: Stories and Art from the 2020 Pandemic" tags={['Graphic design', 'Editorial design', 'Layout', 'Illustration']}>
         <div className="project_details">
            <div className="colspan-4 colspan-first">
               <h4 className='upper'>Client:</h4>
               <p>YAR Collective</p>
               <h4 className="upper">Credits:</h4>
               <p><b>Art Direction and Editorial Design:</b> Muhaddisa Shahzad<br/><b>Curated and Edited:</b> Rabab Shahzad</p>
               <p>Created in collaboration with Yar Design Lab</p>
            </div>
            <div className="colspan-4 project_desc">
               <h4>Education | Design | 2020</h4>
               <p>During the 2020 Coronavirus Pandemic, YAR Collective embarked on a project to curate an inspiring zine, spotlighting the exceptional artistic talents within their community. This project documented individuals' unique experiences during the pandemic, highlighting how art provided them with solace and resilience. As the pandemic drove interactions online, YAR aimed to craft an e-zine, offering a digital sanctuary to their creative community to share ideas, enjoy and engage despite the challenges.</p>
               <p>For the project, I drew inspiration from YAR Collective's distinctive brand elements to craft the overall stylistic approach for the content, including captions, pull quotes, body text, and headlines. The creative brief called for an experimental design, yet it needed to maintain a sense of structure. As a solution, I opted for a versatile five-column grid system, which allowed for extensive experimentation while ensuring a cohesive layout. In addition to layout design, I illustrated stickers that aligned with the zine's theme—celebrating creativity during quarantine and resilience in trying times.</p>
            </div>
            <div className="clearfix"></div>
         </div>
         <section className="project_images mn-grid">
            <div className="grid-1">
               {images.map(image => (
                  <LazyImage name={image.name} padding={image.padding} />
               ))}
            </div>
            <div className="clearfix"></div>
         </section>
      </ProjectView>
   );
}

export default CoroniclesProjectView;