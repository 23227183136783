import { useEffect, useRef, useState } from 'react';
import { classNames } from '../utils';

function LazyImage({
   name,
   padding,
   src,
}) {
   const [loading, setLoading] = useState(true);
   const [srcd, setSrcd] = useState(false);
   const divElement = useRef();

   const projectName = window.location.href.match(/[\w-]+$/)[0];
   const imageSrc = src ? src : `/assets/${projectName}/${name}`;

   useEffect(() => {
      LazyImage.io.observe(divElement.current);
      const divElementRef = divElement.current;
      divElementRef.setSrcd = setSrcd;
      return () => {
         LazyImage.io.unobserve(divElementRef);
      };
   }, []);

   function onLoad() {
      setLoading(false);
   }

   return (
      <div ref={divElement} className={classNames({
         'lazy': true,
         'lazy--loading': loading
      })} style={{paddingBottom: `${padding}%`}}>
         <img
            onLoad={onLoad}
            src={srcd ? imageSrc : null}
            data-src={!srcd ? imageSrc : null}
            alt="Design work"
         />
      </div>
   )
}


function observerCallback(entries) {
   entries.forEach(entry => {
      if (entry.intersectionRatio >= 0 && entry.isIntersecting) {
         // setTimeout(() => {
            entry.target.setSrcd(true);
            this.unobserve(entry.target);
         // }, 1000);
      }
   });
}

LazyImage.io = new IntersectionObserver(observerCallback, {
   threshold: [0]
});


export default LazyImage;