import { useState } from 'react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';

function TestimonialsSlider() {
   const quotes = [
      {
         text: "During the course of internship of  8 weeks Maha was given multiple assignments including design, conceptualizing, creative brainstorming and branding. She was creative, confident and completed all the given tasks with utmost sincerity, zeal and enthusiasm. We wish her success in all her future creative endeavors.",
         client: "Yar Collective"
      },
      {
         text: "Third time working with Maha, this time she worked on two designs and both came out great - look forward to the fourth time we'll inevitably work together!",
         client: "Tangle Games"
      },
      {
         text: "Maha was absolutely incredible! She knew exactly what we wanted and delivered the perfect logo for our company. We barely had to make any adjustments and she was so patient with us. We will definitely work with her again :)",
         client: "Hugs and Peaches"
      }
   ]
   const [counter, setCounter] = useState(0);

   function rem(a, d) {
      return (a % d < 0) ? (d + a % d) : a % d;
   }
   
   return (
      <section className="testimonials bg-black padded-sect">
         <div className="m-grid">
            <h2 className='padded-sect'>Don't just take it from me; here's what others think.</h2>
            <div className="clearfix"></div>
            <div className="padded-sect">
               <div className="testimonials_nav">
                  <button className='cta cta--white cta--circle' onClick={() => setCounter(rem(counter - 1,quotes.length))}><BiLeftArrowAlt /></button>
                  <span>{counter + 1}/{quotes.length}</span>
                  <button className='cta cta--white cta--circle' onClick={() => setCounter(rem(counter + 1, quotes.length))}><BiRightArrowAlt /></button>
               </div>
               <div className="testimonials_quote">
                  <blockquote><p className='text-md para-close'>"{quotes[counter].text}"</p></blockquote>
                  <div>— {quotes[counter].client}</div>
               </div>
               <div className="clearfix"></div>
            </div>
         </div>
      </section>
   )
}

export default TestimonialsSlider;