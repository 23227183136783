import { Link } from "react-router-dom";
import { classNames } from "../utils";

function WorkEntry({
   project,
   right
}) {
   return (
      <div className={classNames({
         'work_entry': true,
         'work_entry--right': right
      })}>
         <Link className="work_entry_image" to={'/work/' + project.href}>
            <img src={`/assets/projects/${project.image}.avif`} />
         </Link>
         <p className='para-close'>{project.name}</p>
      </div>
   );
}

export default WorkEntry;