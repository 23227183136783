import { useEffect, useState } from 'react';
import { classNames } from '../utils';

import projects from '../projects';
import WorkEntry from './WorkEntry';


const filters = [
   project => true,
   project => project.cat.includes(0),
   project => project.cat.includes(1),
];

function FilterButton({
   index,
   setSendOut,
   activeButtonIndex,
   setActiveButtonIndex,
   children
}) {
   function onClick() {
      if (index !== activeButtonIndex) {
         setSendOut(true);
         setActiveButtonIndex(index);
      }
   }
   
   return (
      <button className={classNames({
         'cta': true,
         'cta--active': index === activeButtonIndex
      })} onClick={onClick}>{children}</button>
   );
}


function WorkFilterSection() {
   const [activeButtonIndex, setActiveButtonIndex] = useState(0);
   const [activeIndex, setActiveIndex] = useState(0);
   const [sendOut, setSendOut] = useState(false);
   const filteredProjects = projects.filter(filters[activeIndex]);

   useEffect(() => {
      if (sendOut) {
         setTimeout(() => {
            setActiveIndex(activeButtonIndex);
            setSendOut(false);
         }, 400);
      }
   }, [sendOut])
      
   return (
      <section className="work bring-in bring-in-2">
         <div className="work_filter">
            <FilterButton index={0} {...{setSendOut, activeButtonIndex, setActiveButtonIndex}}>All (7)</FilterButton>
            <FilterButton index={1} {...{setSendOut, activeButtonIndex, setActiveButtonIndex}}>Commercial (4)</FilterButton>
            <FilterButton index={2} {...{setSendOut, activeButtonIndex, setActiveButtonIndex}}>Playground (4)</FilterButton>
         </div>
         <div className={classNames({
            'work_entries': true,
            'send-out': sendOut !== false
         })}>
            {filteredProjects.map(project => (
               <WorkEntry project={project} />
            ))}
            <div className="clearfix"></div>
         </div>
      </section>
   );
}

export default WorkFilterSection;