import { Link } from 'react-router-dom';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';

import projects from '../projects';
import LazyImage from '../components/LazyImage';


function ProjectView({
   title,
   tags,
   children
}) {
   const projectName = window.location.href.match(/[\w-]+$/)[0];
   const projectIndex = projects.findIndex(project => project.href === projectName);
   const nextProject = projects[(projectIndex + 1) % projects.length];

   return (
      <>
         <div className="project-image-cont">
            <LazyImage src={`/assets/heroes/${projectName}.avif`} padding={56.25} />
         </div>
         <div className="m-grid">
            <section className="project logofolio padded-sect">
               <div className="project_showcase">
                  <div className="colspan-4 colspan-none--tablet project_tags">
                     {tags.map((tag, i) => (
                        <span className="cta cta--tag" key={i}>{tag}</span>
                     ))}
                  </div>
                  <div className="colspan-4 colspan-none--tablet colspan-first">
                     <h1>{title}</h1>
                  </div>
                  <div className="clearfix"></div>
                  {children}
               </div>
               <div className="project_next">
                  <div className="colspan-4 colspan-first">
                     <div><Link to="/work" className="cta"><BiLeftArrowAlt/> More projects</Link></div>
                  </div>
                  <div className="colspan-4">
                     <p className='para-near h1'><span>Up next...</span> <Link to={'../' + nextProject.href} className="cta cta--circle cta--black"><BiRightArrowAlt /></Link></p>
                     <Link style={{display: 'block'}} to={'../' + nextProject.href}>
                        <div className="work_entry_image"><img src={`/assets/projects/${nextProject.image}.avif`} alt="" /></div>
                        <p className='para-near'>{nextProject.name}</p>
                     </Link>
                  </div>
                  <div className="clearfix"></div>
               </div>
            </section>
         </div>
      </>
   );
}

export default ProjectView;