import LazyImage from '../../components/LazyImage';
import ProjectView from '../Project';
import images from '../../data/mood.json';


function MoodView() {
   return (
      <ProjectView title="Mood Jewelry" tags={['Strategic identity', 'Collateral design', 'Packaging']}>
         <div className="project_details">
            <div className="colspan-4 colspan-first">
               <h4 className='upper'>Client:</h4>
               <p>Self-initiated</p>
            </div>
            <div className="colspan-4 project_desc">
               <h4>Fashion | Jewelry | 2024 </h4>
               <p>Mood is a jewelry brand specializing in hoops, known for its sustainable craftsmanship and ethical sourcing. The brand aims to empower women to make bold choices by providing versatile designs that transition from casual to formal settings. The name "Mood" reflects the brand's commitment to offering pieces that suit various moods, styles, and occasions and the phrase, “limitless style with sentiment” is an extension to the idea of versatility and an emotion or sentiment for the piece whether it's personal or environmental. </p>
               <p>The brand identity, represented by the display font "Kica," exudes boldness and femininity, with the letter "O" serving as a submark resembling a hoop earring. The brand's color palette contributes to an empowering and warm vibe, emphasizing versatility. The color cinnabar, in particular, plays a key role, acting as a versatile hue that complements the other colors seamlessly. In terms of art direction, the brand opts for photography that is approachable yet bold.</p>
            </div>
            <div className="clearfix"></div>
         </div>
         <section className="project_images mn-grid">
            <div className="grid-1">
               {images.map(image => (
                  <LazyImage name={image.name} padding={image.padding} />
               ))}
            </div>
            <div className="clearfix"></div>
         </section>
      </ProjectView>
   );
}

export default MoodView;