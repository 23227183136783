import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { BiDownArrowAlt, BiRightArrowAlt } from 'react-icons/bi';

function Accordion({
   heading,
   question,
   text
}) {
   const divRef = useRef();
   const [shown, setShown] = useState(false);
   let divTransitionDuration = useRef();

   useEffect(() => {
      divTransitionDuration.current = parseFloat(getComputedStyle(divRef.current).transitionDuration) * 1000;
   }, []);

   function toggleDrawer() {
      const divElement = divRef.current;
      if (!shown) {
         divElement.style.display = 'block';
         const height = divElement.offsetHeight;
         divElement.style.height = 0;
         setTimeout(() => {
            divElement.style.height = height + 'px';
         });
         setShown(true);
      }
      else {
         setShown(false);
         divElement.style.height = 0;
         setTimeout(() => {
            divElement.style = null;
         }, divTransitionDuration.current);
      }
   }

   return (
      <div>
         <div className="colspan-6 colspan-inline accordion">
            <div className="accordion_toggler" onClick={toggleDrawer}>
               {heading ? <h3>{heading}</h3> : <p className="para-close">{question}</p>}
               <span className={'accordion_icon cta cta--circle' + (shown ? ' cta--black' : '')}><BiDownArrowAlt /></span>
            </div>
            <div ref={divRef} className="accordion_drawer">
               <p>{text}</p>
            </div>
         </div>
         <div className="clearfix"></div>
      </div>
   );
}

export default Accordion;