import TestimonialsSlider from '../components/TestimonialsSlider';
import WorkFilterSection from '../components/WorkFilterSection';

function WorkView() {
   return (
      <>
         <section className="colspan-6 colspan-none--tablet bring-in">
            <div className="m-grid padded-sect">
               <h1>Work</h1>
               <p className="h2">Discover a diverse range of projects, from local to international, showcasing brand identity and other graphic design work.</p>
            </div>
         </section>
         <div className="clearfix"></div>
         <div className="m-grid padded-sect">
            <WorkFilterSection />
         </div>
         <TestimonialsSlider/>
      </>
   );
}

export default WorkView;