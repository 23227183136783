import LazyImage from '../../components/LazyImage';
import ProjectView from '../Project';
import images from '../../data/yar.json';


function YarProjectView() {
   return (
      <ProjectView title="YAR Playshop" tags={['Strategic identity', 'Graphic design', 'Illustration']}>
         <div className="project_details">
            <div className="colspan-4 colspan-first">
               <h4 className='upper'>Client:</h4>
               <p>YAR Collective</p>
               <h4 className="upper">Credits:</h4>
               <p><b>Art Direction:</b> Muhaddisa Shahzad</p>
               <p>Created at Yar Design Lab Agency</p>
            </div>
            <div className="colspan-4 project_desc">
               <h4>Education | Design | 2019</h4>
               <p>YAR Playshops are creative workshops initiated by YAR Collective, aimed at helping designers, creatives, and artists master new skills. The project involved creating the brand identity for the Playshops, illustration for an Instagram post and web banner to announce launch on social media and designing playful badges to reward participants. In crafting the brand identity, I conceptualized a circular wordmark to encapsulate the Playshops' friendly ambiance. </p>
               <p>Notably, the clever interplay between the 'L' and 'Y' in the wordmark subtly signifies the collaborative essence, symbolizing the convergence of creatives eager to expand their skill sets. Additionally, the illustration sought to transform the identity into a dynamic universe, portraying it as a space where ideas, knowledge, and execution evolve seamlessly in orbit. This approach effectively communicates the Playshops' inclusive and skill-centric environment while infusing an element of playfulness and creativity into the design elements.  </p>
            </div>
            <div className="clearfix"></div>
         </div>
         <section className="project_images mn-grid">
            <div className="grid-1">
               {images.map(image => (
                  <LazyImage name={image.name} padding={image.padding} />
               ))}
            </div>
            <div className="clearfix"></div>
         </section>
      </ProjectView>
      // <ProjectView>
      //    <div className="project_details">
      //       <h1 className='colspan-6'></h1>
      //       <p className='colspan-6'>YAR Playshops are creative workshops initiated by YAR Collective, aimed at helping designers, creatives, and artists master new skills. The project involved creating the brand identity for the Playshops, an illustration for an Instagram post and web banner to announce the launch on social media, and designing playful badges to reward participants. In crafting the brand identity, I conceptualized a circular wordmark to encapsulate the Playshops' friendly ambiance.</p>
      //       <p className="colspan-6">Notably, the clever interplay between the 'L' and 'Y' in the wordmark subtly signifies the collaborative essence, symbolizing the convergence of creatives eager to expand their skill sets. Additionally, the illustration sought to transform the identity into a dynamic universe, portraying it as a space where ideas, knowledge, and execution evolve seamlessly in orbit. This approach effectively communicates the Playshops' inclusive and skill-centric environment while infusing an element of playfulness and creativity into the design elements. </p>
      //       <p className="colspan-6">
      //          Year: 2019<br/>
      //          Industry: Arts and Culture<br/>
      //          Client: YAR Collective<br/>
      //          Skills: Brand Identity, Graphic design, Illustration, Typography <br/>
      //          <br/>
      //          CREDITS:<br/>
      //          Art Direction — Muhaddisa Shahzad<br/>
      //          <br/>
      //          Created at Yar Design Lab Agency
      //       </p>
      //    </div>
      //    <section className="project_images">
      //       <div className="grid-1">
      //          {images.map(image => (
      //             <LazyImage name={image.name} padding={image.padding} />
      //          ))}
      //       </div>
      //    </section>
      // </ProjectView>
   );
}

export default YarProjectView;