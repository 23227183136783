import LazyImage from '../../components/LazyImage';
import ProjectView from '../Project';
import images from '../../data/adore.json';


function AdoreProjectView() {
   return (
      <ProjectView title="Adore Bakery & Cafe" tags={['Editorial design', 'Graphic design', 'Collateral design', 'Packaging']}>
         <div className="project_details">
            <div className="colspan-4 colspan-first">
               <h4 className='upper'>Client:</h4>
               <p>Self-initiated</p>
            </div>
            <div className="colspan-4 project_desc">
               <h4>Cafes | Bakery | 2022</h4>
               <p>The allure of the aroma and texture of freshly baked goods like bread, croissants, or buns is a sensation akin to love. It's an experience that warms the heart and exudes cheerfulness. Adore, a hypothetical artisan bakery brand, wanted to share this cherished experience, and the identity was meticulously crafted to evoke those sentiments. Guided by this concept, I curated a vibrant and contrasting color palette, bespoke branding elements, and a collection of illustrations that bestow a distinctive personality upon the brand. </p>
               <p>These hand-drawn graphic elements drew inspiration from the very essence of what we adore – the ingredients utilized in baking, the intricate actions of bread-making, and the delightful variety of bread itself. Complementing the visual components, Adore's verbal representation reflected the same warmth and cheerfulness that permeated their craft. Phrases like "Hot oven and warm smiles" and "baking your day much breadier" were thoughtfully chosen to articulate the heartfelt core of the brand.  </p>
            </div>
            <div className="clearfix"></div>
         </div>
         <section className="project_images mn-grid">
            <div className="grid-1">
               {images.map(image => (
                  <LazyImage name={image.name} padding={image.padding} />
               ))}
            </div>
            <div className="clearfix"></div>
         </section>
      </ProjectView>
   );
}

export default AdoreProjectView;