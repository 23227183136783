import RootView from './Root';

function ErrorSection() {
   return (
      <section className="error bring-in">
         <h1>404!</h1>
      </section>
   );
}

function ErrorView() {
   return (
      <RootView element={<ErrorSection />} />
   );
}

export default ErrorView;