import ProjectView from '../Project';
import images from '../../data/logofolio.json';
import LazyImage from '../../components/LazyImage';


function LogofolioProjectView() {
   return (
      <ProjectView title="Logofolio" tags={['Strategic identity', 'Graphic design']}>
         <div className="project_details">
            <div className="colspan-4 colspan-empty colspan-first">
            </div>
            <div className="colspan-4 project_desc">
               <p>From 2021 to 2022, I had the privilege of working with various brands, both local and international and did some personal projects spanning across diverse industries such as education, entertainment, fashion, and retail. As someone deeply passionate about brand identity, I take immense pride in presenting my portfolio, which showcases my exploration of different styles and ideas. </p>
            </div>
            <div className="clearfix"></div>
         </div>
         <section className="project_images mn-grid">
            <div className="grid-1">
               {images.map(image => (
                  <LazyImage name={image.name} padding={image.padding} />
               ))}
            </div>
            <div className="clearfix"></div>
         </section>
      </ProjectView>
   );
}

export default LogofolioProjectView;