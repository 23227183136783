import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import RootView from './views/Root';
import HomeView from './views/Home';
import AboutView from './views/About';
import WorkView from './views/Work';
import ErrorView from './views/Error';
import LogofolioProjectView from './views/Projects/Logofolio';
import PersonalProjectView from './views/Projects/Personal';
import AdoreProjectView from './views/Projects/Adore';
import YarProjectView from './views/Projects/Yar';
import Chess101ProjectView from './views/Projects/Chess101';
import CoroniclesProjectView from './views/Projects/Coronicles';
import ContactView from './views/Contact';
import ServicesView from './views/Services';
import FaqsView from './views/Faqs';
import MoodView from './views/Projects/Mood';

const router = createBrowserRouter([
   {
      path: '/',
      element: <RootView />,
      errorElement: <ErrorView />,
      children: [
         {
            path: '/',
            element: <HomeView />
         },
         {
            path: '/about',
            element: <AboutView />
         },
         {
            path: '/contact',
            element: <ContactView />
         },
         {
            path: '/services',
            element: <ServicesView />
         },
         {
            path: '/faqs',
            element: <FaqsView />
         },
         {
            path: '/work',
            children: [
               {
                  path: '',
                  element: <WorkView />,
               },
               {
                  path: 'logofolio',
                  element: <LogofolioProjectView />
               },
               {
                  path: 'personal',
                  element: <PersonalProjectView />
               },
               {
                  path: 'mood',
                  element: <MoodView />
               },
               {
                  path: 'adore',
                  element: <AdoreProjectView />
               },
               {
                  path: 'yar',
                  element: <YarProjectView />
               },
               {
                  path: 'chess-101',
                  element: <Chess101ProjectView />
               },
               {
                  path: 'coronicles',
                  element: <CoroniclesProjectView />
               }
            ]
         }
      ]
   }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <RouterProvider router={router} />
);