import { RxArrowTopRight } from "react-icons/rx";

function ContactView() {
   return (
      <>
         <section className="padded-sect colspan-6 colspan-none--tablet bring-in">
            <div className="m-grid">
               <h1>Contact</h1>
               <p className="h2">Ready to collaborate? You've had a glimpse at my work, and perhaps even checked the FAQs for more information. Now, let's build your brand.</p>
            </div>
         </section>
         <div className="clearfix"></div>

         <section className="padded-sect bg-pink contact-section">
            <div className="m-grid">
               <div className="colspan-4 colspan-none--tablet colspan-empty colspan-first"></div>
               <div className="colspan-4 colspan-none--tablet">
                  <p>This form is important, so please fill it completely with all details, since it is the starting point of your brand building journey. I am a visual person so don't forget to link your Pinterest board with the inspiration you have in mind for your project.</p> 
                  <p>I am currently scheduling clients, so fill out the form and expect to hear back from me within 48 hours. Thank you for choosing me in creating a brand that resonates with you!</p>
                  <p><a className='cta' href="https://docs.google.com/forms/d/e/1FAIpQLSdJculdTWge_BF6HNXqGSMj4c5YNPlzeixeUxB4vLWiS5kaAg/viewform" target="_blank" rel="noreferrer noopener">View contact form <RxArrowTopRight /></a></p>
               </div>
               <div className="clearfix"></div>
            </div>
         </section>
      </>
   );
}

export default ContactView;