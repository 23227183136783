import Accordion from '../components/Accordion';
import Package from '../components/Package';
import Processes from '../components/Processes';

function ServicesView() {
   return (
      <>
         <section className="colspan-6 colspan-none--tablet bring-in">
            <div className="m-grid padded-sect services-hero">
               <h1>Services</h1>
               <p className="h2">Explore the range of services I am currently providing to offer you design solutions, that cater to your business needs.</p>
            </div>
         </section>
         <div className="clearfix"></div>

         <section className='m-grid padded-sect'>
            <div className='colspan-4 colspan-none--tablet colspan-first'>
               <h2>Strategic brand design service</h2>
               <p className='para-close' style={{ marginTop: 15 }}>In order to build your brand's unique presence these are the essential services I offer to craft a solid brand identity.</p>
            </div>
            <div className="clearfix"></div>
            <div className='text-right'>
               <Accordion heading="STRATEGY" text="Helping discover your brand's potential direction, by analyzing your brand's purpose, market and persona collaboratively in strategy sessions." />
               <Accordion heading="CREATIVE DIRECTION" text="Before delivering a visual identity system, it is crucial to understand your brand's creative territory or vibe to attract your target audience in the form of mood boards." />
               <Accordion heading="BRAND IDENTITY" text="Capturing the true essence of the brand beyond a logo in an identity system. Providing a single concept for your identity in different lockups or signatures where each lockup has its own unique purpose and usage." />
            </div>
         </section>

         <section className='bg-pink padded-sect rounded'>
            <div className="m-grid text-center">
               <div className="colspan-4 colspan-none--tablet colspan-inline colspan-first">
                  <h2>Other graphic design services</h2>
                  <p>These are the additional graphic design services that I provide to help build your brand across different channels.</p>
               </div>
               <div className="clearfix"></div>
               <div>
                  <Accordion heading="PACKAGING" text="Packaging is a touch-point in the brand design process and I tend to provide simple packaging solutions, designed effectively, ensuring they provide the brand its consistent look and feel." />
                  <Accordion heading="MARKETING COLLATERALS" text="I provide you with marketing collaterals or templates that help in creating a cohesive brand, amplifying your brand recognition such as stationary design, thank you cards or posters etc." />
                  <Accordion heading="EDITORIAL DESIGN" text="Designing layouts and compositions for your zines, books or brochures, in such a way that they help in creating a visually appealing reading experience for your target audience." />
                  <Accordion heading="SOCIAL MEDIA TEMPLATES" text="Providing you with ready-to-use social media templates that help in building a stronger brand presence across digital and speed your workflow." />
                  <Accordion heading="BRAND GUIDELINES" text="Developing a guideline system to ensure your entire brand elements remain consistent not just internally but also externally that helps in maintaining brand consistency." />
               </div>
            </div>
         </section>

         <section className="bg-black padded-sect packages">
            <div className="m-grid">
               <div className="colspan-4 colspan-first">
                  <h2>Design packages catering to your brand and budget needs.</h2>
               </div>
               <div className="colspan-4">
                  <p className='para-close'>Yes, I offer different packages in my design proposal for different design needs. This is to give you different options to choose from that helps you in deciding what works best for your brand and budget.</p>
               </div>
               <div className="clearfix"></div>
               <div className='padded-sect packages-cards'>
                  <Package heading="Starter package" body="The starter package offers the bare essentials because it has everything that you need as a starting point to build your brand." />
                  <Package heading="Standard package" body="The standard package is an extension to the starter package. It covers extra marketing collateral's, social media templates and brand guidelines to maintain brand consistency." />
                  <Package heading="Custom package" body="Looking at your design needs, the custom package is your one-stop solution, since it covers all the essential services required to get your brand off the ground. " />
                  <div className="clearfix"></div>
               </div>
            </div>
         </section>

         <Processes/>
      </>
   );
}

export default ServicesView;