import LazyImage from '../../components/LazyImage';
import ProjectView from '../Project';
import images from '../../data/chess-101.json';


function Chess101ProjectView() {
   return (
      <ProjectView title="Chess 101 Brochure" tags={['Editorial design', 'Graphic design']}>
         <div className="project_details">
            <div className="colspan-4 colspan-first">
               <h4 className='upper'>Client:</h4>
               <p>Nativ Learning</p>
               <h4 className="upper">Credits:</h4>
               <p><b>Art Direction:</b> Muhaddisa Shahzad</p>
               <p>Created at Yar Design Lab Agency</p>
            </div>
            <div className="colspan-4 project_desc">
               <h4>Education | Game | 2019</h4>
               <p>Nativ Learning, a startup committed to democratizing education, introduced Chess 101, their online course focusing on teaching the fundamentals of chess. Backed by decades of research on the positive impact of chess on cognitive abilities, the course marked their inaugural offering in English and Urdu, by being accessible to learners of all backgrounds.To promote their pilot project effectively on launch day, they opted to provide a glimpse into their online course through brochures designed in both languages.</p>
               <p>These brochures not only offered step-by-step instructions for enrolling in the course and shed light on their mission but also integrated a chessboard directly into the brochures, inviting readers to experience it firsthand.To create a clean and minimal design, I opted for a gate-fold brochure layout that offered ample space for both languages. This design effectively accommodated the chess game and provided essential information to prospective learners.</p>
            </div>
            <div className="clearfix"></div>
         </div>
         <section className="project_images mn-grid">
            <div className="grid-1">
               {images.map(image => (
                  <LazyImage name={image.name} padding={image.padding} />
               ))}
            </div>
            <div className="clearfix"></div>
         </section>
      </ProjectView>
   );
}

export default Chess101ProjectView;