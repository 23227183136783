import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import HomeReel from '../components/HomeReel';
import WorkSection from '../components/WorkSection';
import TestimonialsSlider from '../components/TestimonialsSlider';



function NicheCard({
   heading,
   description,
   image
}) {
   return (
      <article className='niche-card sticky'>
         <h3 className='upper'>{heading}</h3>
         <p>{description}</p>
         <img src={'/assets/sector/' + heading + '.png'} alt="" />
      </article>
   );
}


function HomeView() {
   return (
      <>
         <section className="hero">
            <div className="m-grid hero_info text-center">
               <h1 className="bring-in">Graphic designer.<br/>Layout enthusiast.<br/>Strategic brand designer.</h1>
               <div className="bring-in bring-in-2 colspan-4 colspan-none--tablet colspan-inline">
                  <p>Hi! I’m Maha Adnan. I am passionate about helping your business — fashion, interior, education or cafe — build a strategic brand, in a collaborative way, so that you can elevate its true essence with a cohesive system.</p>
               </div>
            </div>
            <HomeReel />
         </section>

         <section className='bg-pink padded-sect second-hero'>
            <div className="m-grid">
               <div className='colspan-4 colspan-first'>
                  <h2 style={{marginRight: 10}}>A brand not all over the place but just in one place — the right place.</h2>
               </div>
               <div className='colspan-4'>
                  <p>Yes, you heard it right! A strategic brand is crafted with a very well defined strategy. Before the creative process begins, every aspect of the brand – from its purpose, values, and personality to its messaging – is meticulously articulated to achieve a set of goals for a specific audience. Now compare it with a tangled up brand developed haphazardly, you know what is missing, strategy. </p>
                  <p>Your brand is a creative opportunity and can only be meaningful when it showcases your true passion, purpose and unique story with zeal and consistency, otherwise your meandering aimlessly in a sea of competition, without clarity.</p>
                  <p><Link className='cta' to='/services'>Learn More</Link></p>
               </div>
               <div className="clearfix"></div>
            </div>
         </section>

         <WorkSection/>

         <section className='m-grid padded-sect padded-sect--white'>
            <div className='colspan-4 colspan-none--tablet colspan-first'>
               <h2>Crafting cohesive brand identities that builds trust</h2>
            </div>
            <div className="clearfix"></div>
            <div className='colspan-3 colspan-4--tablet colspan-first'>
               <p>With almost four years of industry experience, I like to prioritize understanding the essence of your brand or its foundational purpose, before crafting the identity (look and feel). Approaching a new brand as a creative challenge, I eagerly embrace it by asking pertinent questions that need to be answered. </p>
            </div>
            <div className="colspan-3 colspan-4--tablet">
               <p>This ensures clear communication and helps generate the best possible outcomes for your brand. Moreover, I believe in a collaborative approach, as it is both efficient and transparent, fostering genuine connections over transactional relationships.</p>
            </div>
            <div className="clearfix"></div>
            <div><Link to="/about" className="cta">Learn More</Link></div>
         </section>

         <section className='m-grid padded-sect padded-sect--white niche'>
            <div className="colspan-4 colspan-none--tablet niche_right">
               <div className="sticky">
                  <h2>Not here for everyone but just the ones I truly love to work with.</h2>
                  <p>Over the years, I've discovered the industries I thoroughly enjoy collaborating with to creating standout identities for and where I aim to develop my expertise. Here are the lists of the possible brands that can fall in each category and can help you in determining if your brand is that one.  </p>
               </div>
            </div>
            <div className="colspan-4 colspan-none--tablet colspan-first niche_boxes">
               <NicheCard heading="Fashion" description="Fashion is an ever-evolving canvas of self-expression, a fusion of aesthetics and innovation that reflects individuality and culture. If yours is a fashion brand, I'd love to collaborate with you." />
               <NicheCard heading="Education" description="Education builds spaces for our knowledge to flourish and is a driver to shape the future. If your brand is committed to learning/education, I would be delighted to work together!" />
               <NicheCard heading="Cafes" description="Cafes are ever-present in neighborhoods, cherished as havens for social connections and relaxation. If yours is a café dedicated to serving non-alcoholic beverages, I'd be thrilled to collaborate with your brand." />
               <NicheCard heading="Interior" description="Interior design enhances the lives of those who inhabit them, with the functional and visually pleasing spaces. If your brand falls in this category, I'm eager to collaborate with you." />
            </div>
            <div className="clearfix"></div>
         </section>
         
         <TestimonialsSlider/>
      </>
   )
}

export default HomeView;