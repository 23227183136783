function Package({
   heading,
   body
}) {
   const [headingLine1, headingLine2] = heading.split(' ');
   return (
      <div className="colspan3-1 package">
         <h3>{headingLine1}<br/>{headingLine2}</h3>
         <p>{body}</p>
      </div>
   )
}

export default Package;