import { useRef } from "react";
import { useEffect } from "react";

function ProcessStep({
   heading,
   body
}) {
   return (
      <div className="process-step">
         <div className="process-step_inner">
            <h3>{heading}</h3>
            <p>{body}</p>
         </div>
      </div>
   )
}

function Processes() {
   const sectionRef = useRef();
   const processBarThumbRef = useRef();
   const processStepsContRef = useRef();

   useEffect(() => {
      const sectionElement = sectionRef.current;
      const processStepsContElement = processStepsContRef.current;
      const processBarThumbElement = processBarThumbRef.current;

      const heightDifference = sectionElement.offsetHeight - sectionElement.firstElementChild.offsetHeight;
      const perStepScrollY = heightDifference / processStepsContElement.children.length;
      const processBarThumbDistance = processBarThumbElement.parentNode.offsetHeight - processBarThumbElement.offsetHeight;

      function scrollHandler() {
         const distanceFromTop = sectionElement.getBoundingClientRect().top + window.scrollY;
         const distanceOfBottomFromTop = sectionElement.getBoundingClientRect().bottom + window.scrollY - window.innerHeight;

         if (distanceFromTop <= window.scrollY && window.scrollY < distanceOfBottomFromTop) {
            var dx = window.scrollY - distanceFromTop;
            var index = Math.floor(dx / perStepScrollY);
            if (index === 7) {
               return;
            }

            processBarThumbElement.firstElementChild.textContent = `0${index + 1}`;
            processBarThumbElement.style.transform = `translateY(${(processBarThumbDistance / heightDifference) * dx}px)`;

            for (var element of processStepsContElement.children) {
               element.style.display = 'none';
            }
            const processStepElement = processStepsContElement.children[index];
            // This conditional makes sure that when we are at exactly the last
            // point, where the computed `index` is beyond how many steps we have,
            // we don't set properties on an `undefined` value.
            if (processStepElement) {
               processStepElement.style.display = 'block';
               processStepElement.classList.add('bring-in');
            }
         }
         else {
            if (window.scrollY < distanceFromTop) {
               processBarThumbElement.style.transform = 'translateY(0)';
            }
            else {
               processBarThumbElement.style.transform = `translateY(${processBarThumbDistance}px)`;
            }
         }
      }
      window.addEventListener('scroll', scrollHandler);
      return () => {
         window.removeEventListener('scroll', scrollHandler);
      }
   }, []);

   return (
      <section ref={sectionRef} className='processes-section m-grid'>
         <div className='padded-sect processes-section_sticky'>
            <div>
               <div className="colspan-3 colspan-first colspan-empty"></div>
               <div className="colspan-5">
                  <h2>Still unsure, lets walk you through the entire process now</h2>
               </div>
               <div className="clearfix"></div>
            </div>
            <div className='padded-sect flex'>
               <div className="colspan-3 colspan-first">
                  <div className="process-bar">
                     <div className="process-bar_line"></div>
                     <div ref={processBarThumbRef} className="process-bar_thumb gradient">
                        <div className="process-bar_thumb_text bg-black">01</div>
                     </div>
                  </div>
               </div>
               <div className="colspan-5 process-steps-grid">
                  <div className='process-steps-cont' ref={processStepsContRef}>
                     <ProcessStep heading="ENQUIRY" body="This stage is your opportunity to get in contact with me after completing the inquiry form. Once I review your responses, we can discuss the project scope during an enquiry session, to determine if we should move forward to the next stage." />
                     <ProcessStep heading="SIGNED AND SECURED" body="The project sounded ideal during the inquiry phase. Now, I'll provide a tailored proposal, detailing the project scope, timeline, and budget. Once accepted, you'll receive a contract and 50% deposit invoice to proceed." />
                     <ProcessStep heading="ONBOARDING" body="You have accepted the proposal, signed the contract and paid the invoice. I will now onboard you to a custom Trello workspace, where all further communications and work will take place." />
                     <ProcessStep heading="STRATEGY AND CREATIVE DIRECTION" body="We'll work together through a series of strategy sessions to develop your brand strategy document. Then, after strategy, I'll provide you with creative direction in the form of mood boards, and once a direction is decided, we'll progress further." />
                     <ProcessStep heading="IDENTITY" body="After finalizing the creative direction for your brand and using insights from the strategy stage, I'll provide you with a single strong concept for a new identity system in the form of a presentation, so you can envision how your brand identity will appear in real-world contexts." />
                     <ProcessStep heading="ACTIVATION" body="After incorporating your feedback into the identity (if there are any changes), I will present the concept again. If there are no changes, I'll start working on the essential brand touch-points, including print, social media, and brand guidelines, among others, we decided on in the proposal earlier." />
                     <ProcessStep heading="OFF-BOARDING" body="After you've paid the invoice for the remaining 50% payment and provided your final feedback on your experience working with me, I will send you the final files via the workspace. After project sign-off, I will get back to you in a few weeks to check if everything is coming along nicely." />
                  </div>
               </div>
               <div className="clearfix"></div>
            </div>
         </div>
      </section>
   );
}

export default Processes;