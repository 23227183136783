import { BiRightArrowAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import projects from '../projects';
import WorkEntry from './WorkEntry';


function WorkSection() {
   return (
      <section className="m-grid padded-sect work">
         <h2>Work</h2>
         <div className="work_entries">
            <WorkEntry project={projects[0]} />
            <div className="clearfix"></div>
            <WorkEntry project={projects[1]} right />
            <div className="clearfix"></div>
            <WorkEntry project={projects[2]} />
            <div className="clearfix"></div>
         </div>
         <div className="clearfix"></div>
         <Link to="/work" className="cta"><span>See all work</span> <BiRightArrowAlt /></Link>
      </section>
   );
}

export default WorkSection;