import LazyImage from '../../components/LazyImage';
import ProjectView from '../Project';
import images from '../../data/personal.json';


function PersonalProjectView() {
   return (
      <ProjectView title="Personal Identity" tags={['Strategic identity', 'Graphic design', 'Collateral design', 'Guidelines']}>
         <div className="project_details">
            <div className="colspan-4 colspan-first">
               <h4 className='upper' style={{marginTop: 0}}>Client:</h4>
               <p>Self-initiated</p>
            </div>
            <div className="colspan-4 project_desc">
               <p>In 2021, I embarked on creating my personal brand with the intention of effectively conveying my passion and dedication as a visual designer, allowing me to showcase my professional journey. Throughout the development phase, my primary objective was to design something that authentically reflected my personal style — simple, minimalist, adaptive, bold, and memorable. This comprehensive endeavor extended beyond the mere creation of a logo; it encompassed the construction of an entire visual identity.</p>
               <p>For the logo, I crafted a geometric mark using basic circles, chosen to radiate an approachable vibe while maintaining balance. To further enhance the brand identity, I selected Manrope typeface — a versatile and adaptable one, suitable for both print and the web. It adeptly strikes a harmonious balance between semi-geometric and semi-grotesque forms, and its adaptive nature aligns it seamlessly with my brand identity.</p>
            </div>
            <div className="clearfix"></div>
         </div>
         <section className="project_images mn-grid">
            <div className="grid-1">
               {images.map(image => (
                  <LazyImage name={image.name} padding={image.padding} />
               ))}
            </div>
            <div className="clearfix"></div>
         </section>
      </ProjectView>
   );
}

export default PersonalProjectView;