import { RxHamburgerMenu } from "react-icons/rx";
import { LiaTimesSolid } from "react-icons/lia";
import { Link, useLocation } from 'react-router-dom';

import { classNames } from '../utils';
import { useEffect, useState } from "react";


function NavLink({
   href,
   children
}) {
   const location = useLocation();
   return (
      <Link className={classNames({
         'link': true,
         'sel': location.pathname.startsWith(href)
      })} to={href}>{children}</Link>
   );
}


function Header() {
   const [navShown, setNavShown] = useState(false);

   useEffect(() => {
      document.body.classList[navShown ? 'add' : 'remove']('no-scroll');
   }, [navShown])

   function toggleNav() {
      if (!navShown) {
         document.documentElement.scrollTop = 0;
      }
      setNavShown(!navShown);
   }

   function navClick(e) {
      if (e.target.matches('a')) {
         setNavShown(false);
      }
   }
   
   return (
      <header className='m-grid'>
         <Link to="/" className='logo'>
            <img alt="" onClick={() => setNavShown(false)} src="/assets/logo-black.png" />
         </Link>
         <nav className={classNames({
            'nav': true,
            'nav--shown': navShown,
            'bring-in': navShown
         })} onClick={navClick}>
            <NavLink href="/about">About</NavLink>
            <NavLink href="/work">Work</NavLink>
            <NavLink href="/services">Services</NavLink>
            <NavLink href="/faqs">FAQs</NavLink>
            <Link className='cta' to="/contact">Contact</Link>
         </nav>
         <button className="cta header_menu" onClick={toggleNav}>
            {!navShown ? <RxHamburgerMenu/> : <LiaTimesSolid/>}
         </button>
      </header>
   );
}

export default Header;