export default [
   {
      image: 1,
      name: 'Logofolio',
      href: 'logofolio',
      cat: [0, 1]
   },
   {
      image: 2,
      name: 'Coronicles',
      href: 'coronicles',
      cat: [0]
   },
   {
      image: 3,
      name: 'Chess 101',
      href: 'chess-101',
      cat: [0]
   },
   {
      image: 6,
      name: 'Adore Bakery & Cafe',
      href: 'adore',
      cat: [1]
   },
   {
      image: 5,
      name: 'YAR Playshop',
      href: 'yar',
      cat: [0]
   },
   {
      image: 7,
      name: 'Mood Jewelry',
      href: 'mood',
      cat: [1]
   },
   {
      image: 4,
      name: 'Personal Branding',
      href: 'personal',
      cat: [1]
   },
];