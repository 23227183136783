import { useEffect, useRef, useState } from 'react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';

import Footer from '../components/Footer';
import Header from '../components/Header';


function RootView() {
   const divRef = useRef();
   const mainRef = useRef();

   let showContact = true;
   const location = useLocation();
   if (location.pathname.startsWith('/contact')) {
      showContact = false;
   }

   useEffect(() => {
      if (showContact) {
         var done = false;
         function scrollHandler() {
            var distanceOfBottomFromBottom = mainRef.current.parentNode.getBoundingClientRect().bottom + window.scrollY - window.innerHeight;
            if (window.scrollY > distanceOfBottomFromBottom) {
               if (!done) {
                  divRef.current.style.height = mainRef.current.offsetHeight + 'px';
                  mainRef.current.style.height = mainRef.current.offsetHeight + 'px';
                  mainRef.current.style.position = 'fixed';
                  mainRef.current.style.top = `${window.innerHeight - divRef.current.offsetHeight}px`;
                  done = true;
               }
               // divRef.current.style.opacity = 1 - (window.scrollY - distanceOfBottomFromBottom) / window.innerHeight;
            }
            else {
               divRef.current.style = null;
               mainRef.current.style = null;
               done = false;
            }
         }
         window.addEventListener('scroll', scrollHandler);
         return () => {
            window.removeEventListener('scroll', scrollHandler);
         }
      }
      else {
         divRef.current.style = null;
         mainRef.current.style = null;
         done = false;
      }
   }, [location, showContact]);

   
   return (
      <>
         <ScrollRestoration />
         <Header />
         <div ref={divRef}>
            <main ref={mainRef}>
               <Outlet/>
            </main>
         </div>
         <Footer showContact={showContact} />
      </>
   );
}

export default RootView;