import { BiUpArrowAlt } from "react-icons/bi";
import { Link, useNavigation } from "react-router-dom";

function Footer({
   showContact
}) {
   function onClick(e) {
      e.preventDefault();
      document.documentElement.scrollTo({ behavior: 'smooth', top: 0 })
   }
   return (
      <div className="last-sect">
         {showContact && (
            <section className="contact bg-pink padded-sect">
               <div className="m-grid">
                  <p></p>
                  <h2 className="">Ready to create meaningful designs?<br />Reach out to collaborate!</h2>
                  <p><Link to="/contact" className="cta cta--black">Inquire Now</Link></p>
               </div>
            </section>
         )}
         <footer className="footer padded-sect m-grid">
            <div className="colspan-3 footer_top-nav">
               <a href="#top" onClick={onClick}><span className="cta cta--circle"><BiUpArrowAlt/></span> <span className="h3 upper">&nbsp; Back to top</span></a>
            </div>
            <div className="colspan-5 colspan-none--mob colspan-first">
               <div className="footer_email">
                  <p className="h3">For general inquiries</p>
                  <p className="h1">hello@mahaadnan.design</p>
               </div>
            </div>
            <div className="clearfix"></div>
            <div className="padded-sect">
               <div className="colspan-1 colspan-first">
                  <h4>Sitemap</h4>
                  <ul>
                     <li><Link className="link" to="/about">About</Link></li>
                     <li><Link className="link" to="/work">Work</Link></li>
                     <li><Link className="link" to="/services">Services</Link></li>
                     <li><Link className="link" to="/faqs">FAQs</Link></li>
                  </ul>
               </div>
               <div className="colspan-1">
                  <h4>Social</h4>
                  <ul>
                     <li><a className="link" href="https://www.instagram.com/mahaadnan.design">Instagram</a></li>
                     <li><a className="link" href="https://www.linkedin.com/in/maha-adnan-838224207/">LinkedIn</a></li>
                     <li><a className="link" href="https://www.behance.net/mahaadnan-bhatti">Behance</a></li>
                  </ul>
               </div>
               <div className="colspan-3 colspan-empty"></div>
               <div className="colspan-3 footer_credits">
                  <h4>Credits</h4>
                  <p className="para-close">All the content has been written in Manrope. The website has been designed by Maha Adnan and developed by <a href="https://github.com/bilalbro">Bilal</a>.</p>
                  <p className="text-xs para-close">© Maha Adnan 2023</p>
               </div>
               <div className="clearfix"></div>
            </div>
         </footer>
         <div className="ribbon gradient"></div>
      </div>
   );
}

export default Footer;