import Accordion from "../components/Accordion";

function FaqsView() {
   return (
      <>
         <section className="colspan-6 colspan-none--tablet bring-in">
            <div className="m-grid padded-sect">
               <h1>FAQs</h1>
               <p className="h2">Got questions in mind? Below are some that might provide the answers you're looking for. You can send me yours if you do not find them in this list.</p>
            </div>
         </section>
         <div className="clearfix"></div>

         <section className='m-grid padded-sect' style={{ paddingBottom: 160 }}>
            <div className='text-right'>
               <Accordion
                  question="How do I contact you for a query?"
                  text="You can email me at hello@mahaadnan.design."
               />
               <Accordion
                  question="What is your general response time to email queries or contact forms?"
                  text="My response time to email queries or contact forms is usually within 48 hours."
               />
               <Accordion
                  question="What are your payment terms?"
                  text="I usually ask for 50% of the total amount upfront and the rest after the project is complete."
               />
               <Accordion
                  question="What happens if I am not satisfied with the design?"
                  text="In case of cancellation you will be invoiced an amount of the work completed up to the date of cancellation or the amount of the advance deposit made for the project. The work however remains my property but will be available to you in case you resume the project."
               />
               <Accordion
                  question="What files will I receive at the end of the project?"
                  text="AI, EPS, PDF, PNG & JPEG files are that you will receive at the end of the project."
               />
               <Accordion
                  question="Do you offer ongoing support after the project is completed?"
                  text="I do like to check in a couple of weeks after the completion of a project, in case you need any assistance. Apart from that, I offer ongoing retainer service where you can continue to work with me for any design or brand needs that arise. Think of it as a design partner on call, ready to help with updates, tweaks, or even new projects that come up down the road."
               />
               <Accordion
                  question="Do you work for brands in categories other than fashion, education, interior and coffee cafes?"
                  text="No, I don't. Currently these are the only sectors I would like to provide my design services to."
               />
               <Accordion
                  question="Do you offer printing services along with designing materials?"
                  text="No, but I always try to provide you print-ready design files that meet all the necessary requirements."
               />
               <Accordion
                  question="What are your criteria for charging clients?"
                  text="I tailor my rates based on the services I offer, my level of expertise, and the value I can deliver to you. Additionally, I consider the location of your business to factor in the cost of living and prevailing market prices for design services in your region. I ensure that my pricing reflects the value you will receive from my work and is transparently communicated."
               />
               <Accordion
                  question="How much time do you take to complete a project?"
                  text="Timeframes can obviously vary per project depending on the project scope and amount of deliverables but I always discuss an estimated time frame in an enquiry session."
               />
            </div>
         </section>
      </>
   );
}

export default FaqsView;