function ValueContainer({
   heading,
   body
}) {
   return (
      <div class="value-cont">
         <div className='value-cont_icon'><img src={`/assets/icons/value-${heading.toLowerCase()}.png`}/></div>
         <div className="value-cont_num h3">0{++ValueContainer.count}</div>
         <div className="value-cont_text">
            <h3 className='h3'><span className='value-cont_h3_num'>0{ValueContainer.count}</span> {heading}</h3>
            <p className='para-near'>{body}</p>
         </div>
         <div className="clearfix"></div>
      </div>
   );
}

ValueContainer.count = 0;

function AboutView() {
   return (
      <>
         <section className="m-grid">
            <div className="about padded-sect flex">
               <div className="colspan-3 colspan-none--tablet colspan-first about_left">
                  <div className="bring-in about_image sticky"></div>
               </div>
               <div className="colspan-5 colspan-none--tablet bring-in">
                  <h1 className='h2'>I'm Maha Adnan!<br/>An independent graphic designer.</h1>
                  <p>My primary focus is on creating thoughtful and strategic design solutions for businesses, particularly in the fashion, interior, cafe, and education sectors. My goal is not only to create visually pleasing designs but also to ensure they showcase the passion and purpose of the brands I work with.</p>
                  <p>Ever since I graduated in Communication Design from Indus Valley School of Art and Architecture in 2020, I've tried to intersect the inherent creativity of a right-brained thinker into the realm of brand strategy. This has helped me in creating strategic brand identities, while also bringing my enthusiasm for crafting layouts and additional graphic design work.</p>
                  <p>Having a genuine love for learning extends to my passion for pursuing personal projects, providing a creative outlet that fosters inspiration, free from commercial pressures.</p>
               </div>
               <div className="clearfix"></div>
            </div>
         </section>

         <section className="m-grid padded-sect about-details">
            <div className="colspan-2 colspan-none--tablet colspan-first">
               <h4 className='upper'>Experience</h4>
               <ul>
                  <li>STRATEGIC BRAND DESIGNER<br/>Freelance<br/>Aug 2020- Current</li>
                  <li>VASL<br/>Illustration Intern<br/>July 2020 - Aug 2020</li>
                  <li>YAR COLLECTIVE<br/>Design Intern<br/>July 2019 - Aug 2019</li>
               </ul>
            </div>
            <div className="colspan-2 colspan-none--tablet">
               <h4 className='upper'>EDUCATION</h4>
               <ul>
                  <li>INDUS VALLEY SCHOOL OF ART AND ARCHITECTURE<br/>B.Des in Communication Design, 2020</li>
               </ul>
            </div>
            <div className="colspan-2 colspan-none--tablet">
               <h4 className='upper'>CERTIFICATIONS</h4>
               <ul>
                  <li>OVERALL DISTINCTION FOR YEAR 2020<br/>Communication Design Department at IVS</li>
                  <li>DISTINCTION IN DESIGN THESIS FOR YEAR 2020<br/>Communication Design Department at IVS</li>
               </ul>
            </div>
            <div className="colspan-2 colspan-none--tablet">
               <h4 className='upper'>CONTACT</h4>
               <ul>
                  <li>
                     EMAIL
                     <br/>
                     <a className="link link--lined" href="mailto:hello@mahaadnan.design">hello@mahaadnan.design</a>
                  </li>
                  <li>
                     ELSEWHERE
                     <br/>
                     <a className="link link--lined" href="https://www.instagram.com/mahaadnan.design">Instagram</a>
                     <br/>
                     <a className="link link--lined" href="https://www.linkedin.com/in/maha-adnan-838224207/">LinkedIn</a>
                     <br/>
                     <a className="link link--lined" href="https://www.behance.net/mahaadnan-bhatti">Behance</a>
                  </li>
               </ul>
            </div>
            <div className="clearfix"></div>
         </section>

         <section className='padded-sect bg-black rounded'>
            <div className="m-grid">
               <h2>Values (05).</h2>
               <p className='colspan-4 colspan-none--tablet colspan-first colspan-inline'>I like to be upfront about my values and what principles I like to follow in general and also when I am working on projects. </p>
               <div>
                  <ValueContainer
                     heading="Collaboration"
                     body="I believe in working collectively to foster genuine connections. By utilizing effective communication and mutual respect, a joyful experience is created that helps in bringing the best possible outcomes for your brand."
                     icon=""
                  />
                  <ValueContainer
                     heading="Strategy"
                     body="I value structure or a systematic approach that helps take a brand in the right direction. By asking questions and identifying valuable insights, it helps to craft a brand in its true essence."
                     icon=""
                  />
                  <ValueContainer
                     heading="Curiosity"
                     body="Change is inevitable, so I believe in having a continuous learning spirit. By fostering an open mindset that embraces new knowledge, ideas, and experiences, I feel it inspires and helps in self improvment. "
                     icon=""
                  />
                  <ValueContainer
                     heading="Creativity"
                     body="I believe one should challenge existing knowledge by thinking out of the box and embrace failure or risk as they are part and parcel of the creative process. By continually pushing boundaries, I strive to deliver impactful design solutions."
                     icon=""
                  />
                  <ValueContainer
                     heading="Consistency"
                     body="Establishing a reliable and disciplined process upholds high standards and ensures consistent delivery with a clear purpose. As a result, I am able to generate meaningful outcomes in reasonable amounts of time.  "
                     icon=""
                  />
                  <div className="clearfix"></div>
               </div>
            </div>
         </section>
      </>
   );
}

export default AboutView;